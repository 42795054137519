import { DateField, Props as DateFieldProps, Format, Status } from "@doorstead/components/fields/DateField";
import { isEmpty } from "lodash";
import { useState } from "react";
import { getYearMonthAndDateFromDateString } from "../../../utils/getYearMonthAndDateFromDateString";
import { toPrefixZero } from "./toPrefixZero";

type Props = Omit<DateFieldProps, "format" | "value" | "onChange"> & {
  fromYear: number;
  toYear: number;
  value: string | null;
  onChange: (value: string | null) => void;
  validateOnBlur?: boolean;
  validator?: (value: string | null) => boolean;
};

export function ApplicantDateField({
  value = "",
  onChange,
  fromYear,
  toYear,
  validateOnBlur = true,
  validator,
  status,
  ...props
}: Props) {
  const [isValid, setIsValid] = useState<boolean>(true);
  const getFieldStatus = (value: string | null) => {
    if (validateOnBlur && validator) {
      return isValid ? (isEmpty(value) ? Status.Default : Status.Correct) : Status.Error;
    }
    return status || Status.Default;
  };

  return (
    <DateField
      {...props}
      fromYear={fromYear}
      toYear={toYear}
      value={getYearMonthAndDateFromDateString(value)}
      format={Format["MM/dd/yyyy"]}
      onChange={(value) => {
        if (value === null) {
          onChange(null);
        } else {
          onChange(`${value.year}-${toPrefixZero(value.month)}-${toPrefixZero(value.date)}`);
        }
      }}
      status={getFieldStatus(value)}
      onBlur={() => {
        // run validation on blur if set
        if (validateOnBlur && validator) {
          const result = validator(value);
          setIsValid(!!result);
        }
      }}
    />
  );
}
