import { Divider, Flex } from "@chakra-ui/react";
import { PrimaryButton, useIsMobile } from "@doorstead/components";
import { getValue } from "src/@types/AutoPay/getValue";
import { AutoPayDate } from "src/@types/AutoPay/typings";
import { PAY_ACCOUNT_BALANCE_AMOUNT } from "src/components/SetupAutopayBox/SetupAutopayBox";
import { useAutoPay } from "src/hooks/useAutoPay";
import { useCreateAutoPay } from "src/hooks/useCreateAutoPay";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useIsTPO } from "src/hooks/useIsTPO";
import { usePaymentMethods } from "src/hooks/usePaymentMethods";
import { useProratedRentDue } from "src/hooks/useProratedRentDue";
import { useNextStep } from "../../../../../contexts/StepContext/useNextStep";
import { PaidOffBox } from "../../../../PaidOffBox";
import { BaseCard } from "../BaseCard";

export function PaidCard() {
  const isMobile = useIsMobile();
  const { nextStep, isLoading } = useNextStep();
  const analytics = useGroupAnalytics();
  const balanceDue = useProratedRentDue();
  const autopay = useAutoPay();
  const paymentMethods = usePaymentMethods();
  const { createAutoPay, isLoading: isCreatingAutopay } = useCreateAutoPay();
  const firstPaymentMethod = paymentMethods.length > 0 ? paymentMethods[0] : null;
  const isTPO = useIsTPO();

  return (
    <BaseCard title="Make payments">
      <PaidOffBox balanceDue={balanceDue} />
      <Divider />
      <Flex w="100%" justifyContent="space-between">
        <PrimaryButton
          isLoading={isLoading || isCreatingAutopay}
          disabled={isLoading || isCreatingAutopay}
          isFullWidth={isMobile}
          onClick={() => {
            analytics?.track("TP_OB_PAY_RENT_CONTINUED");
            if (!isTPO && autopay === null && firstPaymentMethod) {
              createAutoPay(
                {
                  amount: PAY_ACCOUNT_BALANCE_AMOUNT,
                  date: getValue(AutoPayDate.TWENTY_EIGHTH),
                  paymentMethodId: firstPaymentMethod.paymentMethodId,
                },
                {
                  onSuccess: nextStep,
                }
              );
            } else {
              nextStep();
            }
          }}
        >
          Continue
        </PrimaryButton>
      </Flex>
    </BaseCard>
  );
}
