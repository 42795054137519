import { Status, TextField, Props as TextFieldProps } from "@doorstead/components/fields/TextField";
import _, { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";

export type Props = Omit<TextFieldProps, "value" | "onChange"> & {
  defaultValue: string | null;
  transform?: (value: string) => string;
  getStatus?: (value: string) => Status;
  onSyncToServer: (value: string) => void;
  debounce?: number;
  validateOnBlur?: boolean;
  validator?: (value: string) => boolean;
};

export function ApplicantTextField({
  id,
  defaultValue,
  onSyncToServer,
  transform = (e) => e,
  getStatus,
  debounce = 1500,
  validateOnBlur = true,
  validator,
  autoComplete = "off",
  ...textProps
}: Props) {
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, debounce), [onSyncToServer]);
  const [value, setValue] = useState<string>(transform(defaultValue || ""));
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  const getFieldStatus = (value: string) => {
    if (validateOnBlur && validator) {
      return isValid ? (isEmpty(value) ? Status.Default : Status.Correct) : Status.Error;
    }
    return getStatus ? getStatus(value) : Status.Default;
  };

  return (
    <TextField
      {...Object.assign(textProps)}
      id={id}
      value={value}
      onChange={(e) => {
        const value = transform(e.target.value);
        setValue(value);
        if (isValid) debouncedSyncToServer(value);
      }}
      status={getFieldStatus(value)}
      autoComplete={autoComplete}
      onBlur={(e) => {
        const currentValue = e.target.value;
        // run validation on blur if set
        if (validateOnBlur && validator) {
          const result = validator(currentValue);
          setIsValid(!!result);
        }
      }}
    />
  );
}
