import { VStack } from "@chakra-ui/react";
import { useApplicantPageView } from "../../../../../hooks/applicants/useApplicantPageView";
import { ApplicantOnboardingStage } from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../../../hooks/applicants/useFetchedApplicationMe";
import { useUpdateApplicantField } from "../../../../../hooks/applicants/useUpdateApplicantField";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useScrollIntoView } from "../../../../../hooks/useScrollIntoView";
import { maxWidth, padding, width } from "../../config";
import { EmploymentsCard } from "./EmploymentsCard";
import { ProofOfIncomeCard } from "./ProofOfIncomeCard";

export function EmploymentAndIncomeSection() {
  const applicantAnalytics = useApplicantPageView("TP_VERIFICATION_SECTION_VIEWED");
  const scrollIntoView = useScrollIntoView();
  const { mutate } = useUpdateApplicantOnboardingStage();
  const { mutate: updateApplicant } = useUpdateApplicantField();
  const {
    applicantById: { isCurrentlyEmployed },
  } = useFetchedApplicationMe();
  const values = useFetchedApplicant()
    .employments.edges.map((e) => e.node)
    .filter((node) => node.isCurrent) // Only show current employments
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  return (
    <VStack spacing={8} id="/2">
      <EmploymentsCard
        idField="applicantEmploymentId"
        hasValue={isCurrentlyEmployed}
        targetField="isCurrentlyEmployed"
        updateSource={updateApplicant}
        values={values}
        id="/2/children/0"
        eventName="EMPLOYMENT"
        padding={padding}
        width={width}
        maxWidth={maxWidth}
        onContinue={() => {
          applicantAnalytics?.track("TP_EMPLOYMENTS_CARD_CONTINUED");
          scrollIntoView("/2/children/1");
        }}
      />
      <ProofOfIncomeCard
        id="/2/children/1"
        padding={padding}
        width={width}
        onContinue={async () => {
          applicantAnalytics?.track("TP_PROOF_OF_INCOME_CARD_CONTINUED");
          await mutate(ApplicantOnboardingStage.SUBMIT_AND_PAY_SECTION);
        }}
      />
    </VStack>
  );
}
