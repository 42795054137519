import { Box, Flex, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { GrayText, OutlineButton, PrimaryButton, hooks } from "@doorstead/components";
import { useState } from "react";
import { AddPaymentMethodModal } from "src/components/evergreens/EverGreenPaymentMethods/AddPaymentMethodModal";
import { context, useInternalState } from "src/components/evergreens/EverGreenPaymentMethods/useInternalState";
import { useNextStep } from "src/contexts/StepContext/useNextStep";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useAddress } from "../../../../../hooks/useAddress";
import { usePaymentMethods } from "../../../../../hooks/usePaymentMethods";
import { PaymentMethod } from "../../../../PaymentMethod";

export function Content() {
  const { onOpen } = useInternalState();
  const address = useAddress();
  const { isLoading, nextStep } = useNextStep();
  const isMobile = hooks.useIsMobile();
  const paymentMethods = usePaymentMethods();
  const analytics = useGroupAnalytics();
  const hasVerifiedPaymentMethod = paymentMethods.some((pm) => pm.microDepositStatus === null);

  return (
    <>
      <AddPaymentMethodModal />
      <GrayText mt={3}>{address}</GrayText>
      <Flex w="100%" justifyContent="space-between" flexWrap="wrap" gap={3} my={6}>
        <Box>
          <Text fontWeight="bold" fontSize="xl">
            Your payment methods
          </Text>
        </Box>
        <Box>
          <OutlineButton
            size="md"
            onClick={() => {
              analytics?.track("TP_OB_ADD_PAYMENT_METHOD_CLICKED");
              onOpen();
            }}
          >
            Add payment method
          </OutlineButton>
        </Box>
      </Flex>
      <VStack gap={5}>
        {paymentMethods.map((pm) => (
          <PaymentMethod {...pm} key={pm.paymentMethodId} />
        ))}
      </VStack>
      <Flex flexDirection="column" mt={6} alignItems="flex-start">
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading || !hasVerifiedPaymentMethod}
          isFullWidth={isMobile}
          onClick={() => {
            analytics?.track("TP_OB_PAYMENT_METHOD_CONTINUED");
            nextStep();
          }}
        >
          Continue
        </PrimaryButton>
      </Flex>
    </>
  );
}

export function AddMorePaymentMethodsCard() {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <context.Provider value={{ loading, setLoading, isOpen, onOpen, onClose }}>
      <Content />
    </context.Provider>
  );
}
