import { Box, Button, Center, Flex, HStack, Img, Stack, VStack } from "@chakra-ui/react";
import { PrimaryButton } from "@doorstead/components";
import { GoogleMapImage } from "@doorstead/components/GoogleMapImage";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useIsCoverPageGetStartedNewDesignEnabled } from "src/hooks/useIsCoverPageGetStartedNewDesign";
import { useLogClickEvent } from "src/hooks/useLogClickEvent";
import { useAddress } from "../../../hooks/applicants/useAddress";
import { useApplicantPageView } from "../../../hooks/applicants/useApplicantPageView";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useFetchedApplicationMe } from "../../../hooks/applicants/useFetchedApplicationMe";
import { useUpdateApplicantField } from "../../../hooks/applicants/useUpdateApplicantField";
import { useGoogleMapKey } from "../../../hooks/useGoogleMapKey";
import { getSegmentedAddress } from "../../../hooks/useSegmentedAddress";
import { useTrackImpressionEvent } from "../../../hooks/useTrackImpressionEvent";
import { ApplicantFeeBox } from "./ApplicantFeeBox/ApplicantFeeBox";
import { ThankYouBox } from "./ApplicantFeeBox/ThankYouBox";
import { InfoGrid } from "./InfoGrid";

type ListingPhoto = {
  bucket: string;
  key: string;
  filename: string;
  size: number;
  url: string;
  upload_by: string;
  show: boolean;
};

export function ApplicantLandingCardV2() {
  const isMobile = useIsMobile();
  const address = useAddress();
  const googleMapKey = useGoogleMapKey();
  const {
    doorsteadUser,
    applicantId,
    applicationRequest: { property, isTpoHideApplicationFeeFeature, listing },
  } = useFetchedApplicant();
  const photoUrls = JSON.parse(listing?.photoUrls || "[]");
  const [seg1, seg2] = getSegmentedAddress(property);
  const me = useFetchedApplicationMe();
  const { mutate: updateApplicantField, isLoading } = useUpdateApplicantField();
  const logClickEvent = useLogClickEvent();
  const hideApplicationFees = isTpoHideApplicationFeeFeature || false;
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source") || "";
  const isCoverPageGetStartedNewDesign = useIsCoverPageGetStartedNewDesignEnabled();

  const getPropertyPhotoUrl = (photoUrls: Array<ListingPhoto>) => {
    for (const photo of photoUrls) {
      if (photo?.url) {
        return photo.url;
      }
    }
    return "";
  };
  const propertyPhotoUrl = getPropertyPhotoUrl(photoUrls);

  useApplicantPageView("TP_APPLICATION_COVERPAGE_V2_VIEWED");

  useTrackImpressionEvent({
    data: {
      ownerId: doorsteadUser.doorsteadUserId,
      applicantId: applicantId,
      applicationRequestId: me.applicantById.applicationRequest.applicationRequestId,
      applicationRequest: me.applicantById.applicationRequest,
      source: source,
      hideApplicationFees,
    },
    propertyId: property.propertyId,
    email: doorsteadUser.email,
    action: "TP_APPLICATION_COVERPAGE_V2_VIEWED_RDS",
    surface: "APPLICATION_PORTAL",
  });

  // old design
  if (!isCoverPageGetStartedNewDesign) {
    return (
      <VStack w="100%" alignItems="center" spacing={5}>
        {me.applicantById.doorsteadUser.firstName && (
          <Center fontWeight="bold" color="#1A202C" fontSize="3xl">
            Hello, {me.applicantById.doorsteadUser.firstName}
          </Center>
        )}
        {isMobile && (
          <PrimaryButton
            isLoading={isLoading}
            onClick={async () => {
              await updateApplicantField({
                coverPageGetStartedAt: new Date().toISOString(),
              });
              await logClickEvent({
                email: doorsteadUser.email || "",
                propertyId: property.propertyId || "",
                data: {
                  property,
                  first_name: doorsteadUser.firstName,
                  address: property.fullAddress,
                  doorsteadUserId: doorsteadUser.doorsteadUserId,
                  applicantId: me.applicantById.applicantId,
                  hideApplicationFees,
                  source: source,
                  isCoverPageGetStartedNewDesign,
                },
                action: "TP_APPLICATION_COVERPAGE_V2_GET_STARTED_CLICKED",
                surface: "APPLICANT_PORTAL",
              });
            }}
          >
            Get started
          </PrimaryButton>
        )}

        <VStack w="100%" alignItems="flex-start" bgColor="white" borderRadius={20} p={isMobile ? 4 : 10} spacing={5}>
          <HStack w="100%" alignItems="center" spacing={5}>
            {!isMobile && (
              <Box w="fit-content" h="100%">
                <GoogleMapImage
                  borderRadius={6}
                  width={isMobile ? 322 : 240}
                  height={isMobile ? 220 : 220}
                  address={address}
                  googleKey={googleMapKey}
                />
              </Box>
            )}
            <VStack alignItems="flex-start" w="100%" spacing={3}>
              <Box color="gray.500" fontSize="md" lineHeight="24px">
                Rental property address
              </Box>
              <VStack spacing={0} alignItems="flex-start" fontWeight="bold" fontSize="xl" color="gray.700">
                <Box>{seg1}</Box>
                <Box>{seg2}</Box>
              </VStack>
              <InfoGrid />
            </VStack>
          </HStack>
          {!isMobile && <ThankYouBox />}
          <Stack
            direction={isMobile || hideApplicationFees ? "column" : "row"}
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            {!hideApplicationFees && <ApplicantFeeBox />}
            {isMobile && <ThankYouBox />}
            {!isMobile && (
              <PrimaryButton
                isLoading={isLoading}
                size="lg"
                onClick={async () => {
                  await updateApplicantField({
                    coverPageGetStartedAt: new Date().toISOString(),
                  });
                  await logClickEvent({
                    email: doorsteadUser.email || "",
                    propertyId: property.propertyId || "",
                    data: {
                      property,
                      first_name: doorsteadUser.firstName,
                      address: property.fullAddress,
                      doorsteadUserId: doorsteadUser.doorsteadUserId,
                      applicantId: me.applicantById.applicantId,
                      source: source,
                      hideApplicationFees,
                      isCoverPageGetStartedNewDesign,
                    },
                    action: "TP_APPLICATION_COVERPAGE_V2_GET_STARTED_CLICKED",
                    surface: "APPLICANT_PORTAL",
                  });
                }}
              >
                Get started
              </PrimaryButton>
            )}
          </Stack>
        </VStack>
      </VStack>
    );
  }
  // new design
  return (
    <Flex
      direction="column"
      w="100%"
      h={isMobile ? "auto" : "calc(100vh - 230px)"}
      alignItems="center"
      justifyContent="center"
      background="radial-gradient(
          40% 40% at 35% center,
          rgba(255, 154, 154, 0.50) 0%,
          rgba(255, 156, 157, 0.3578) 13.77%,
          rgba(255, 157, 160, 0.2563) 38.93%,
          rgba(255, 159, 164, 0.0780) 68.67%,
          rgba(255, 160, 166, 0) 93.06%
        ),
        radial-gradient(
          27.26% 25.74% at 74.43% 51.65%,
          rgba(51, 68, 220, 0.15) 4.99%,
          rgba(45, 117, 224, 0) 100%
        )"
      backgroundBlendMode="normal"
    >
      <Box mb="6" mt={isMobile ? "10" : "0"}>
        <Center fontWeight="bold" color="#1A202C" fontSize={isMobile ? "2xl" : "3xl"} textAlign="center">
          Don&apos;t miss out — apply now before it&apos;s taken!
        </Center>
      </Box>

      <VStack
        w="100%"
        alignItems="flex-start"
        bgColor="white"
        borderRadius={20}
        padding={isMobile ? "20px 16px" : 5}
        spacing={5}
        mb="10"
      >
        <HStack w="100%" p="0" spacing={isMobile ? 5 : 10} alignItems="flex-start">
          <Box h={isMobile ? "98px" : "100%"}>
            {!isEmpty(propertyPhotoUrl) && (
              <Img
                height={isMobile ? "100%" : "195px"}
                width={isMobile ? "auto" : "auto"}
                src={propertyPhotoUrl}
                borderRadius="8px"
              />
            )}
            {isEmpty(propertyPhotoUrl) && (
              <Box w="fit-content" h="100%">
                <GoogleMapImage
                  borderRadius={6}
                  width={isMobile ? 98 : 195}
                  height={isMobile ? 98 : 180}
                  address={address}
                  googleKey={googleMapKey}
                />
              </Box>
            )}
          </Box>
          <VStack alignItems="flex-start" w="100%" spacing={2}>
            <Box w="100%" color="gray.500" fontSize="md" lineHeight="24px" pt={isMobile ? "0" : "10px"}>
              Rental property address
            </Box>
            <Box
              w="100%"
              fontWeight="bold"
              fontSize="xl"
              color="gray.700"
              mt={isMobile ? "2px" : "0"}
              pb={isMobile ? "0" : "3"}
            >
              {seg1} {seg2}
            </Box>
            {!isMobile && (
              <Box w="100%">
                <InfoGrid />
              </Box>
            )}
          </VStack>
        </HStack>

        {isMobile && (
          <Box w="100%">
            <InfoGrid />
          </Box>
        )}
      </VStack>
      <Button
        w="304px"
        maxWidth="304px"
        padding="10px 24px"
        mb={isMobile ? "10" : "0"}
        isLoading={isLoading}
        onClick={async () => {
          await updateApplicantField({
            coverPageGetStartedAt: new Date().toISOString(),
          });
          await logClickEvent({
            email: doorsteadUser.email || "",
            propertyId: property.propertyId || "",
            data: {
              property,
              first_name: doorsteadUser.firstName,
              address: property.fullAddress,
              doorsteadUserId: doorsteadUser.doorsteadUserId,
              applicantId: me.applicantById.applicantId,
              source: source,
              hideApplicationFees,
              isCoverPageGetStartedNewDesign,
            },
            action: "TP_APPLICATION_COVERPAGE_V2_GET_STARTED_CLICKED",
            surface: "APPLICANT_PORTAL",
          });
        }}
      >
        Get started
      </Button>
    </Flex>
  );
}
