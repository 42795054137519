import { ApplicationRequestStatus } from "../../../@types/ApplicationRequestStatus";
import { ApplicantNode } from "./Applicant";

export enum ContractType {
  GUARANTEE = "Guarantee",
  TRADITIONAL = "Traditional",
  TRANSFER = "Transfer",
  FLEX = "Flex",
  TPO = "TPO",
}

export type Contract = {
  type: ContractType;
  contractRequest: {
    multistepFlowType: "default" | "tpo" | "tpo_zillow" | "tpo_abbv" | null;
  } | null;
};

export type Listing = {
  listingId: string;
  leaseNegotiationEnabled: boolean;
  price: number;
  photoUrls: string;
  moveInOptions: {
    startDate: string;
    endDate: string;
    price: number;
  }[];
  renewal: {
    contract: Contract;
  };
};

export type ApplicationRequestNode = {
  applicationRequestId: string;
  status: ApplicationRequestStatus;
  negotiatedRent: number;
  inviteCoTenantAndGuarantorAt: string | null;
  isTpoHideApplicationFeeFeature: boolean | null;
  negotiatedMoveInDate: string;
  negotiationLinkStartAt: string | null;
  negotiationLinkEndAt: string | null;
  appStartedAt: string | null;
  appSubmittedAt: string | null;
  primaryApplicant: ApplicantNode;
  useApplicationFlowV2: boolean;
  areAllApplicantsSubmitted: boolean;
  currentSignatureRequest: {
    signatureRequestId: string;
    isLeaseSignedByOwner: boolean;
  };
  property: {
    propertyId: string;
    isCoTenantsInviteV2Feature: boolean | null;
    isEmbeddedLeaseSigningEnabled: boolean | null;
    propertyType: string;
    bedrooms: number | null;
    bathrooms: number | null;
    fullAddress: string;
    addressLine1: string;
    addressLine2: string | null;
    zip: string;
    state: string;
    city: string;
    sqft: number | null;
    timezone: string;
  };
  listing: Listing;
};
