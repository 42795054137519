import { ApplicantFileNode } from "./ApplicantFileNode";
import { ApplicationRequestNode } from "./ApplicationRequest";

export type ApplicantResidenceNode = {
  applicantResidenceId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  moveInDate: string | null;
  moveOutDate: string | null;
  landlordName: string;
  landlordPhone: string;
  landlordEmail: string;
};

export enum ApplicantNodeStatus {
  NEW = "new",
  INCOMPLETE = "incomplete",
  SUBMITTED = "submitted",
  LEASE_SIGNATURE_PENDING = "lease_signature_pending",
  LEASE_SIGNED = "lease_signed",
}

export enum ApplicantOnboardingStage {
  YOUR_INFORMATION_SECTION = "YOUR_INFORMATION_SECTION",
  DOCUMENTS_SECTION = "DOCUMENTS_SECTION",
  EMPLOYMENT_VERIFICATIONS_SECTION = "EMPLOYMENT_VERIFICATIONS_SECTION",
  SUBMIT_AND_PAY_SECTION = "SUBMIT_AND_PAY_SECTION",
}
export type ApplicantOccupantNode = {
  applicantOccupantId: string;
  fullName: string | null;
  createdAt: string;
};

export type ApplicantAnimalNode = {
  applicantAnimalId: string;
  name: string;
  type: string;
  breed: string;
  color: string;
  weightLb: number | null;
  isServiceAnimal: boolean | null;
  hasVerification: boolean | null;
};

export type ApplicantEmploymentNode = {
  applicantEmploymentId: string;
  isCurrent: boolean;
  employer: string;
  jobTitle: string;
  streetAddress: string;
  unitNumber: string;
  state: string;
  country: string;
  city: string;
  zipcode: string;
  annualIncomeInCents: number;
  currentJobStartDate: string | null;
  createdAt: string;
};

export type ApplicantDoorsteadUser = {
  doorsteadUserId: string;
  status: "active" | "imported" | null;
  firstName: string;
  middleName: string;
  lastName: string;
  suffixName: string;
  email: string;
  phone: string;
  passportNumber: string | null;
  driverLicenseNumber: string | null;
  ssnTemp: string | null;
  dayOfBirthTemp: string | null;
  hasBankruptcy: boolean | null;
  isEvicted: boolean | null;
  hasGuilty: boolean | null;
};

export type ApplicantNode = {
  doorsteadUser: ApplicantDoorsteadUser;
  moveInDate: string | null;
  moveOutDate: string | null;
  landlordName: string;
  landlordPhone: string;
  landlordEmail: string;
  desiredLeaseStartAt: string | null;
  createdAt: string;
  coverPageGetStartedAt: string | null;
  status: ApplicantNodeStatus;
  onboardingStage: ApplicantOnboardingStage;
  hasAgreed: boolean;
  proofOfIncomeIsNewJob: boolean;
  applicantId: string;
  addressLine1: string;
  addressLine2: string;
  state: string;
  country: string;
  city: string;
  zipcode: string;
  releaseFormSignature: string;
  salaryIncomePayStubJson: string | null;
  occupants: { edges: { node: ApplicantOccupantNode }[] };
  personaInquiryId: string | null;
  files: { edges: { node: ApplicantFileNode }[] };
  animals: { edges: { node: ApplicantAnimalNode }[] };
  employments: { edges: { node: ApplicantEmploymentNode }[] };
  previousResidences: { edges: { node: ApplicantResidenceNode }[] };
  applicationRequest: ApplicationRequestNode;
  isSalaryIncomeChecked: boolean;
  isSelfEmployedIncomeChecked: boolean;
  isOtherIncomeChecked: boolean;
  hasAdditionalOccupant: boolean;
  hasPet: boolean;
  isCurrentlyEmployed: boolean;
};
