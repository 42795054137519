import { Grid, GridItem } from "@chakra-ui/react";
import { TimelineDirection, useIsMobile } from "@doorstead/components";
import { Step, useStep } from "../../contexts/StepContext";
import { useIsTPO } from "../../hooks/useIsTPO";
import { Timeline } from "./Timeline";
import { DefaultAutoPayStep } from "./steps/DefaultAutoPayStep/DefaultAutoPayStep";
import { MakePaymentStep } from "./steps/MakePaymentStep";
import { PayHoldingDepositStep } from "./steps/PayHoldingDepositStep";
import { PaymentMethodStep } from "./steps/PaymentMethodStep";
import { PendingWalkthroughStep } from "./steps/PendingWalkthroughStep";
import { TPOWelcomeStep } from "./steps/TPOWelcomeStep/TPOWelcomeStep";
import { WelcomeStep } from "./steps/WelcomeStep";
import { useGridProps } from "./useGridProps";

export function Onboarding() {
  const { step } = useStep();
  const isMobile = useIsMobile();
  const gridProps = useGridProps();
  const isTPO = useIsTPO();

  return (
    <Grid {...gridProps}>
      <GridItem display="flex" w="100%" h="100%" justifyContent="center" alignItems="center">
        <Timeline direction={isMobile ? TimelineDirection.Horizontal : TimelineDirection.Vertical} />
      </GridItem>
      <GridItem paddingTop={10} display="flex" justifyContent="center" alignItems={isMobile ? "flex-start" : "center"}>
        {step === Step.WELCOME && <>{isTPO ? <TPOWelcomeStep /> : <WelcomeStep />}</>}
        {step === Step.PAYMENT_METHODS && <PaymentMethodStep />}
        {step === Step.PAY_HOLDING_DEPOSIT && <PayHoldingDepositStep />}
        {step === Step.PENDING_WALKTHROUGH && <PendingWalkthroughStep />}
        {step === Step.MAKE_A_PAYMENT && <MakePaymentStep />}
        {step === Step.AUTO_PAY && <DefaultAutoPayStep />}
      </GridItem>
    </Grid>
  );
}
