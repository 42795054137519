import { useEffect, useRef } from "react";
import { ApplicantAnalytics, useApplicantAnalytics } from "./useApplicantAnalytics";
import { useFetchApplicationMe } from "./useFetchApplicationMe";

export function useApplicantPageView(eventName: string): ApplicantAnalytics {
  const applicationMe = useFetchApplicationMe();
  const applicant = applicationMe.data?.applicantById;
  const analytics = useApplicantAnalytics();
  const isPageViewed = useRef(false);

  useEffect(() => {
    if (analytics && !isPageViewed.current) {
      analytics.track(eventName, {
        applicantId: applicant?.applicantId,
        firstName: applicant?.doorsteadUser?.firstName,
        lastName: applicant?.doorsteadUser?.lastName,
        email: applicant?.doorsteadUser?.email,
        phone: applicant?.doorsteadUser?.phone,
        applicationRequestId: applicant?.applicationRequest?.applicationRequestId,
        listingId: applicant?.applicationRequest?.listing?.listingId,
        propertyId: applicant?.applicationRequest?.property?.propertyId,
        propertyAddress: applicant?.applicationRequest?.property?.fullAddress,
      });
      isPageViewed.current = true;
    }
  }, [analytics]);

  return analytics;
}
