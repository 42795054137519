import { AddPaymentMethodBox } from "src/components/AddPaymentMethodBox";
import { PaymentMethodType } from "src/hooks/useFetchMe";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useIsTPO } from "src/hooks/useIsTPO";
import { EverGreenModal } from "../EverGreenModal/EverGreenModal";
import { useInternalState } from "./useInternalState";

export function AddPaymentMethodModal() {
  const { isOpen, onClose } = useInternalState();
  const analytics = useGroupAnalytics();
  const isTPO = useIsTPO();

  return (
    <EverGreenModal
      isOpen={isOpen}
      onClose={() => {
        analytics?.track("TP_ADD_PAYMENT_METHOD_DISMISSED");
        onClose();
      }}
      title="Add payment method"
    >
      <AddPaymentMethodBox disabledMethods={isTPO ? [PaymentMethodType.Card] : []} />
    </EverGreenModal>
  );
}
