import { Text } from "@chakra-ui/react";
import { OptionsField } from "@doorstead/components";
import { PaymentMethodType } from "src/hooks/useFetchMe";

type Props = {
  value: PaymentMethodType;
  onChange: (value: PaymentMethodType) => void;
  hideSubLabel?: boolean;
  horizontal?: boolean;
  disabledMethods?: PaymentMethodType[];
};

export function PaymentOption({ value, onChange, hideSubLabel, horizontal, disabledMethods = [] }: Props) {
  const paymentOptions = [
    {
      id: PaymentMethodType.ACH,
      value: PaymentMethodType.ACH,
      label: (
        <Text color="gray.800" fontWeight="700">
          Bank account/ACH
        </Text>
      ),
      subLabel: hideSubLabel ? null : (
        <Text color="gray.700" fontWeight="500">
          We use Plaid to securely link to your bank account and routing number.
        </Text>
      ),
    },
    {
      id: PaymentMethodType.Card,
      value: PaymentMethodType.Card,
      label: (
        <Text color="gray.800" fontWeight="700">
          Debit or credit card
        </Text>
      ),
      subLabel: hideSubLabel ? null : (
        <Text color="gray.700" fontWeight="500">
          2.9% transaction fee + 30¢.
        </Text>
      ),
    },
  ];
  return (
    <OptionsField
      forceVertical={!horizontal}
      gap={2}
      options={paymentOptions.filter((option) => !disabledMethods.includes(option.id))}
      value={value}
      onChange={(value) => onChange(value as PaymentMethodType)}
    />
  );
}
