import { PrimaryButton } from "@doorstead/components/buttons";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { useApplicantId } from "../../../hooks/applicants/useApplicantId";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useUpdateApplicantField } from "../../../hooks/applicants/useUpdateApplicantField";
import { useLogClickEvent } from "../../../hooks/useLogClickEvent";

export function StartButton() {
  const isMobile = useIsMobile();
  const applicantId = useApplicantId();
  const navigate = useNavigate();
  const applicant = useFetchedApplicant();
  const { mutate: updateApplicantField, isLoading } = useUpdateApplicantField();
  const logClickEvent = useLogClickEvent();
  return (
    <PrimaryButton
      isFullWidth={isMobile}
      isLoading={isLoading}
      onClick={async () => {
        const applicantUserEmail = applicant.doorsteadUser.email;
        const doorsteadUser = applicant.doorsteadUser;
        const property = applicant.applicationRequest.property;

        await updateApplicantField({
          coverPageGetStartedAt: new Date().toISOString(),
        });
        await logClickEvent({
          email: doorsteadUser.email || "",
          propertyId: property.propertyId || "",
          data: {
            property,
            first_name: doorsteadUser.firstName,
            address: property.fullAddress,
            doorsteadUserId: doorsteadUser.doorsteadUserId,
            applicantId: applicantId,
          },
          action: "TP_APPLICATION_COVERPAGE_V1_GET_STARTED_CLICKED",
          surface: "APPLICANT_PORTAL",
        });

        if (applicant.doorsteadUser.status === "active") {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append("email", applicantUserEmail);
          urlSearchParams.append("applicantId", applicantId);
          navigate(`/application/${applicantId}/login?${urlSearchParams.toString()}`);
        } else {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append("email", applicantUserEmail);
          navigate(`/application/${applicantId}/signup?${urlSearchParams.toString()}`);
        }
      }}
    >
      Get started
    </PrimaryButton>
  );
}
