import { Box, Flex, Image } from "@chakra-ui/react";
import { CardText } from "../../../CardText";

export function Banner() {
  return (
    <Flex
      borderTopRadius={20}
      w="100%"
      flexWrap={{
        sm: "wrap-reverse",
        md: "nowrap",
      }}
      justifyContent="space-between"
      alignItems="center"
      backgroundImage="/resources/bg.svg"
      padding={10}
    >
      <Flex h="100%" maxW="448px" justifyContent="flex-start" flexDirection="column">
        <Box color="gray.400" fontWeight="bold" fontSize="sm">
          Less work. More peace of mind.
        </Box>
        <CardText>Autopay</CardText>
        <Box mt={5} color="gray.700" fontWeight="normal" fontSize="md">
          No one likes late fees. With autopay you can rest assured that your monthly payments are on time every time.
          We do not charge for this service.
        </Box>
      </Flex>
      <Flex minWidth={140} justifyContent="center" alignItems="center">
        <Image boxSize={140} src="/resources/autopay.svg" />
      </Flex>
    </Flex>
  );
}
