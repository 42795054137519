import { AutoPayDate } from "src/@types/AutoPay/typings";
import { UpsertAutoPayCard } from "src/components/evergreens/EverGreenAutoPay/UpsertAutoPayCard";
import { EverGreenModal } from "src/components/evergreens/EverGreenModal/EverGreenModal";
import { useAnalytics } from "src/contexts/analytics";
import { usePaymentMethods } from "src/hooks/usePaymentMethods";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function AddAutopayModal({ isOpen, onClose }: Props) {
  const analytics = useAnalytics();
  const paymentMethods = usePaymentMethods();
  const firstPaymentMethod = paymentMethods.length === 0 ? null : paymentMethods[0];

  return (
    <EverGreenModal
      title="Add autopay"
      isOpen={isOpen}
      onClose={() => {
        analytics?.track("TP_AUTOPAY_DISMISSED");
        onClose();
      }}
    >
      <UpsertAutoPayCard
        isEdit={false}
        initValue={{
          paymentMethodId: firstPaymentMethod?.paymentMethodId || "",
          payOn: AutoPayDate.TWENTY_EIGHTH,
          nextScheduleDate: null,
        }}
        onDone={onClose}
      />
    </EverGreenModal>
  );
}
