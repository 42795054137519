import { ApplicantFileNodeType } from "src/hooks/applicants/useFetchApplicationMe/ApplicantFileNode";
import { isValidSSN } from "src/utils/isValidSSN";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { Labels } from "../../labels";
import { isBirthdayValid } from "../IdentityVerificationSection/BackgroundCheckCard/isBirthdayValid";
import { isValidPhoneNumber } from "../YourInformationSection/NameAndContactInformationCard/formatPhoneNumber";
import { isValid } from "../YourInformationSection/NameAndContactInformationCard/replaceAllowedChars";
import { isAddressLine1Valid } from "../YourInformationSection/ResidenceCard/isAddressLine1Valid";
import { isZipCodeValid } from "../YourInformationSection/ResidenceCard/isZipCodeValid";
import { getPetsError } from "../YourInformationSection/getPetsError";

type Errors = {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  currentResidenceAddressLine1: string | null;
  currentResidenceState: string | null;
  currentResidenceZipCode: string | null;
  employmentState: string | null;
  employmentZipCode: string | null;
  proofOfIncome: string | null;
  dayOfBirthTemp: string | null;
  releaseFormSignature: string | null;
  personaId: string | null;
  phone: string | null;
  pets: string | null;
};

const WARNING_ICON = "⚠️";
const YOUR_INFORMATION = "Your information";
const NAME_AND_CONTACT_INFO = "Name + contact info";
const CURRENT_ADDRESS = "Current address";
const EMPLOYMENT_AND_INCOME = "Employment and income";
const CURRENT_EMPLOYMENT = "Current employment";
const PROOF_OF_INCOME = "Proof-of-income";
const IDENTITY_VERIFICATION = "Identity verification";
const REVIEW_AND_SUBMIT = "Review and submit";
const RELEASE_INFORMATION = "Release of information";

export function getErrorTexts(errors: Errors) {
  return Object.values(errors)
    .filter((error) => error !== null)
    .join("\n");
}

export function useFieldErrors(): { errors: Errors; hasError: boolean } {
  const {
    doorsteadUser,
    releaseFormSignature,
    addressLine1,
    state,
    zipcode,
    country,
    personaInquiryId,
    employments,
    files,
    animals,
  } = useFetchedApplicant();

  let hasError = false;
  const errors: Errors = {
    firstName: null,
    middleName: null,
    lastName: null,
    currentResidenceAddressLine1: null,
    currentResidenceState: null,
    currentResidenceZipCode: null,
    employmentState: null,
    employmentZipCode: null,
    proofOfIncome: null,
    dayOfBirthTemp: null,
    releaseFormSignature: null,
    personaId: null,
    phone: null,
    pets: null,
  };
  const { firstName, middleName, lastName, dayOfBirthTemp, ssnTemp, phone } = doorsteadUser;

  if (firstName === null || firstName.trim().length === 0) {
    errors.firstName = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.FirstName}] is required`;
    hasError = true;
  } else if (!isValid(firstName)) {
    errors.firstName = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.FirstName}] is invalid`;
    hasError = true;
  }

  if (lastName === null || lastName.trim().length === 0) {
    errors.lastName = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.LastName}] is required`;
    hasError = true;
  } else if (!isValid(lastName)) {
    errors.lastName = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.LastName}] is invalid`;
    hasError = true;
  }

  if (middleName !== null && middleName.trim().length > 0 && !isValid(middleName)) {
    errors.middleName = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.MiddleName}] format is invalid`;
    hasError = true;
  }

  if (country === "US") {
    if (addressLine1 === null || addressLine1.trim().length === 0) {
      errors.currentResidenceAddressLine1 = `${WARNING_ICON} [${YOUR_INFORMATION} > ${CURRENT_ADDRESS} > ${Labels.CurrentResidenceAddressLine1}] is required`;
      hasError = true;
    } else if (!isAddressLine1Valid(addressLine1 || "", country)) {
      errors.currentResidenceAddressLine1 = `${WARNING_ICON} [${YOUR_INFORMATION} > ${CURRENT_ADDRESS} > ${Labels.CurrentResidenceAddressLine1}] format is invalid.\n${Labels.CurrentResidenceAddressLine1Format}`;
      hasError = true;
    }

    if (state === null || state.trim().length === 0) {
      errors.currentResidenceState = `${WARNING_ICON} [${YOUR_INFORMATION} > ${CURRENT_ADDRESS} > ${Labels.CurrentResidenceState}] is required`;
      hasError = true;
    }

    if (zipcode === null || zipcode.trim().length === 0) {
      errors.currentResidenceZipCode = `${WARNING_ICON} [${YOUR_INFORMATION} > ${CURRENT_ADDRESS} > ${Labels.CurrentResidenceZipCode}] is required`;
      hasError = true;
    } else if (!isZipCodeValid(zipcode, country)) {
      errors.currentResidenceZipCode = `${WARNING_ICON} [${YOUR_INFORMATION} > ${CURRENT_ADDRESS} > ${Labels.CurrentResidenceZipCode}] format is invalid`;
      hasError = true;
    }
  }

  if (phone === null || phone.trim().length === 0) {
    errors.phone = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > ${Labels.Phone}] is required`;
    hasError = true;
  } else if (country === "US") {
    if (!isValidPhoneNumber(phone)) {
      errors.phone = `${WARNING_ICON} [${YOUR_INFORMATION} > ${NAME_AND_CONTACT_INFO} > US ${Labels.Phone}] format is invalid`;
      hasError = true;
    }
  }

  for (const employmentEdge of employments.edges) {
    const employment = employmentEdge.node;
    if (employment.country === "US") {
      if (employment.state === null || employment.state.trim().length === 0) {
        errors.employmentState = `${WARNING_ICON} [${EMPLOYMENT_AND_INCOME} > ${CURRENT_EMPLOYMENT} > ${Labels.EmploymentState}] is required`;
        hasError = true;
      }

      if (employment.zipcode === null || employment.zipcode.trim().length === 0) {
        errors.employmentZipCode = `${WARNING_ICON} [${EMPLOYMENT_AND_INCOME} > ${CURRENT_EMPLOYMENT} > ${Labels.EmploymentZipCode}] is required`;
        hasError = true;
      } else if (!isZipCodeValid(employment.zipcode, employment.country)) {
        errors.employmentZipCode = `${WARNING_ICON} [${EMPLOYMENT_AND_INCOME} > ${CURRENT_EMPLOYMENT} > ${Labels.EmploymentZipCode}] format is invalid`;
        hasError = true;
      }
    }
  }

  const validProofOfIncomeFileTypes = [
    ApplicantFileNodeType.PREV_THREE_MONTHS_BANK_STATEMENT,
    ApplicantFileNodeType.PREV_TWO_PAY_STUBS,
    ApplicantFileNodeType.TEN_NINE_NINE_TAX_DOCUMENT,
    ApplicantFileNodeType.PREV_X_MONTHS_BANK_STATEMENT,
  ];
  const applicantFiles = files.edges.filter((e) => validProofOfIncomeFileTypes.includes(e.node.type)) || [];
  if (applicantFiles.length < 2) {
    errors.proofOfIncome = `${WARNING_ICON} [${EMPLOYMENT_AND_INCOME} > ${PROOF_OF_INCOME}] At least two proof of income files are required`;
    hasError = true;
  }

  if (!personaInquiryId) {
    errors.personaId = `${WARNING_ICON} [${IDENTITY_VERIFICATION} > Identity verification] is required`;
    hasError = true;
  } else {
    if (dayOfBirthTemp === null || dayOfBirthTemp.trim().length === 0) {
      errors.dayOfBirthTemp = `${WARNING_ICON} [${IDENTITY_VERIFICATION} > ${Labels.Birthday}] is required`;
      hasError = true;
    } else {
      if (!isBirthdayValid(dayOfBirthTemp)) {
        errors.dayOfBirthTemp = `${WARNING_ICON} [${IDENTITY_VERIFICATION} > ${Labels.Birthday}] range should be at least 18 years old`;
        hasError = true;
      }
    }

    if (ssnTemp && !isValidSSN(ssnTemp)) {
      errors.dayOfBirthTemp = `${WARNING_ICON} [${IDENTITY_VERIFICATION} > ${Labels.SSN}] is invalid`;
      hasError = true;
    }
  }

  if (releaseFormSignature === null || releaseFormSignature.trim().length === 0) {
    errors.releaseFormSignature = `${WARNING_ICON} [${REVIEW_AND_SUBMIT} > ${RELEASE_INFORMATION}] Release form signature is required`;
    hasError = true;
  }

  const petsError = getPetsError(animals);
  if (petsError.hasError) {
    errors.pets = petsError.error;
    hasError = true;
  }

  return {
    errors,
    hasError,
  };
}
