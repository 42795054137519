import { useFetchApplicationMe } from "./useFetchApplicationMe";
import { ContractType } from "./useFetchApplicationMe/ApplicationRequest";

export function useUseApplicationFlowV2(): boolean {
  const { isLoading, data } = useFetchApplicationMe();
  if (isLoading) return false;

  return (
    !!data?.applicantById.applicationRequest.useApplicationFlowV2 &&
    data?.applicantById.applicationRequest.listing.renewal.contract.type === ContractType.TPO
  );
}
