import { Box, HStack, StackProps, VStack } from "@chakra-ui/react";
import { OutlineButton } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { PrimaryButton } from "@doorstead/components/buttons";
import { YesNoField } from "@doorstead/components/fields";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import React from "react";
import { useApplicantAnalytics } from "../../../../../../hooks/applicants/useApplicantAnalytics";
import { ApplicantOnboardingStage } from "../../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../../hooks/applicants/useFetchedApplicant";
import { useIsApplicantEditable } from "../../../../../../hooks/applicants/useIsApplicantEditable";
import { useUpdateApplicantOnboardingStage } from "../../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { useUpdateDoorsteadUserField } from "../../../../../../hooks/applicants/useUpdateDoorsteadUserField";
import { CardTitle } from "../../../../CardTitle";

export type Props = StackProps & {
  padding: number;
  width: number | string;
  maxWidth: number | string;
  id: string;
  onContinue: () => void;
};

export const BackgroundCheckCard = React.forwardRef(({ padding, width, maxWidth, id, onContinue }: Props, ref) => {
  const { doorsteadUser } = useFetchedApplicant();
  const applicantAnalytics = useApplicantAnalytics();
  const { mutate: updateOnboarding, isLoading: isOnboardingLoading } = useUpdateApplicantOnboardingStage();
  const disabled = !useIsApplicantEditable();
  const isMobile = useIsMobile();
  const { mutate: updateDoorsteadUserField } = useUpdateDoorsteadUserField();
  const { hasBankruptcy, isEvicted, hasGuilty } = doorsteadUser;

  return (
    <Card id={id} padding={padding} width={width} maxWidth={maxWidth} ref={ref}>
      <VStack width="100%" alignItems="flex-start">
        <CardTitle>Background check</CardTitle>
        <GrayText>The below information will be used to run a background check.</GrayText>
        <VStack gap="3" pt="5" pb="10">
          <Box width="100%">
            <GrayText mb="10px">
              Have you ever filed for bankruptcy, been foreclosed on, or been a defendant in a civil suit?
            </GrayText>
            <YesNoField
              yesLabel="Yes"
              noLabel="No"
              value={hasBankruptcy}
              disabled={disabled}
              onChange={(hasBankruptcy) => {
                updateDoorsteadUserField({
                  hasBankruptcy,
                });
              }}
            />
          </Box>

          <Box width="100%">
            <GrayText mb="10px">Have you ever been evicted from a tenancy or left owing money?</GrayText>
            <YesNoField
              yesLabel="Yes"
              noLabel="No"
              value={isEvicted}
              disabled={disabled}
              onChange={(isEvicted) => {
                updateDoorsteadUserField({
                  isEvicted,
                });
              }}
            />
          </Box>

          <Box width="100%">
            <GrayText mb="10px">
              Have you ever been convicted or pleaded guilty or no contest to any criminal offenses?
            </GrayText>
            <YesNoField
              yesLabel="Yes"
              noLabel="No"
              value={hasGuilty}
              disabled={disabled}
              onChange={(hasGuilty) => {
                updateDoorsteadUserField({
                  hasGuilty,
                });
              }}
            />
          </Box>
        </VStack>

        <HStack w="100%" spacing={5}>
          <OutlineButton
            isLoading={isOnboardingLoading}
            onClick={() => {
              applicantAnalytics?.track("TP_BACKGROUND_CHECK_BACK_CLICKED");
              updateOnboarding(ApplicantOnboardingStage.YOUR_INFORMATION_SECTION);
            }}
          >
            Back
          </OutlineButton>
          <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
            Continue
          </PrimaryButton>
        </HStack>
      </VStack>
    </Card>
  );
});
