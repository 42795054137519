import { Box, Link, Text } from "@chakra-ui/react";
import { useState } from "react";
import { PaymentMethodType } from "src/hooks/useFetchMe";
import { useGroupAnalytics } from "src/hooks/useGroupAnalytics";
import { useRefetchPaymentMethods } from "src/hooks/useRefetchPaymentMethods";
import { SetupACHBox } from "../SetupACHBox";
import { SetupCardBox } from "../SetupCardBox";
import { useInternalState } from "../evergreens/EverGreenPaymentMethods/useInternalState";
import { PaymentOption } from "./PaymentOption";

type AddPaymentMethodBoxProps = {
  disabledMethods?: PaymentMethodType[];
};

export function AddPaymentMethodBox({ disabledMethods = [] }: AddPaymentMethodBoxProps) {
  const { onClose } = useInternalState();
  const { refetchPaymentMethods } = useRefetchPaymentMethods();
  const [method, setMethod] = useState(PaymentMethodType.ACH);
  const analytics = useGroupAnalytics();

  return (
    <>
      <Box my={6}>
        <Text fontWeight="bold" fontSize="xl">
          Choose your payment method
        </Text>
      </Box>
      <PaymentOption value={method} onChange={setMethod} disabledMethods={disabledMethods} />
      {method === PaymentMethodType.ACH && (
        <SetupACHBox
          mt={6}
          onDone={() => {
            analytics?.track("TP_OB_PAYMENT_METHOD_ADDED");
            refetchPaymentMethods();
            onClose();
          }}
        />
      )}
      {method === PaymentMethodType.Card && (
        <SetupCardBox
          mt={6}
          onDone={() => {
            analytics?.track("TP_OB_PAYMENT_METHOD_ADDED");
            refetchPaymentMethods();
            onClose();
          }}
        />
      )}
      <Box mt={6}>
        <Text fontWeight="medium" color="gray.500">
          If you need to choose a different payment method, please email
          <Link
            color="gray.500"
            target="_blank"
            ml={1}
            href="mailto:tenants@doorstead.com"
            _hover={{
              color: "purple.500",
              textDecoration: "underline",
            }}
          >
            tenants@doorstead.com
          </Link>
        </Text>
      </Box>
    </>
  );
}
